import baseTheme from '@narative/gatsby-theme-novela/src/gatsby-plugin-theme-ui';

const fonts = {
  serif: "'Eczar', 'Merriweather', Georgia, Serif",
  sansSerif:
    "'Mukta','SF Pro Display', '-apple-system', 'BlinkMacSystemFont', 'San Francisco', 'Helvetica Neue', 'Helvetica', 'Ubuntu', 'Roboto', 'Noto', 'Segoe UI', 'Arial', sans-serif",
  monospace: `"Operator Mono", Consolas, Menlo, Monaco, source-code-pro, Courier New, monospace`
};

export default {
  ...baseTheme,
  fonts
};
